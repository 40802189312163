<script>
import { mapActions, mapGetters } from "vuex";

import tnVue from "@/mixins/tn.vue";
import TableResizeVue from "@/mixins/table-resize.vue";
import TableNavFunctionsVue from "@/mixins/table-nav-functions.vue";

import PriceListItems from "@/components/price-list-items/price-list-items.vue";
import ProviderList from "./components/provider-list.vue";
import ShowPharmacyRequirements from "@/components/show-pharmacy-requirements.vue";

const urlBasketItems = `BasketLineItem`;

export default {
  components: {
    PriceListItems,
    ProviderList,
    ShowPharmacyRequirements,
  },

  data: () => ({
    ln: "basket",
    uniqKey: "basket",
    selectedRows: [],
    urlBasketItems,
    isDrawer: true,
    isMyMarkup: false,
    isShowItems: false,
    isHide: null,
    markupText: null,
    isShowOrganizationStatistic: undefined,
    isShowOrganizationStatisticReject: undefined,
    leftSideWidth: 470
  }),

  mixins: [tnVue, TableResizeVue, TableNavFunctionsVue],

  computed: {
    size: (vm) => vm.$store.getters.GET_SIZE,

    ...mapGetters("price-list-items", ["GET", "GET_COUNT", "GET_TOTALS"]),

    entriesByProduct: (vm) => vm.GET(urlBasketItems),
    entriesCountByProduct: (vm) => vm.GET_COUNT(urlBasketItems),
    entriesTotals: (vm) => vm.GET_TOTALS(urlBasketItems),

    tableHeight: (vm) => vm.size.height - 170,

    tableHeaders: (vm) =>
      [
        {
          text: "№",
          value: "number",
          width: 10,
          sortable: false,
          _resize: false,
          class: "disable-resize",
        },
        {
          text: "",
          value: "_hasStatistics",
          sortable: false,
          _slot: true,
          _resize: false,
          visible: vm.isShowOrganizationStatistic,
        },
        {
          text: vm.tn("table.priceProvider"),
          value: "priceListProviderName",
          width: vm.$dxcs.priceListProviderName,
          _required: true,
        },
        {
          text: vm.tn("table.productName"),
          value: "productName",
          width: vm.$dxcs.productName,
          _required: true,
        },
        {
          text: vm.tn("table.manufacturerName"),
          value: "manufacturerName",
          width: vm.$dxcs.manufacturer,
          _required: true,
        },
        {
          text: vm.tn("table.shelfLife"),
          value: "shelfLife",
          width: vm.$dxcs.date,
          _filter: (v) => vm.$date(v),
        },
        {
          text: vm.tn("table.priceTypeName"),
          value: "priceTypeName",
          width: vm.$dxcs.priceTypeName,
        },
        {
          text: vm.tn("table.amount"),
          value: "amount",
          _filter: (v) => vm.$sum(v),
          _getColor: (v, item) => {
            const mxp = item.productReferentPriceWholeSalePrice;
            if (v && mxp && v > mxp) return "red";
          },
          width: vm.$dxcs.amount,
          _align: "right",
          _required: true,
        },
        {
          text: vm.tn("table.discount_amount"),
          value: "amountDiscount",
          _filter: (v) => vm.$sum(v),
          _getColor: (v, item) => {
            const mxp = item.productReferentPriceWholeSalePrice;
            if (v && mxp && v > mxp) return "red";
          },
          width: vm.$dxcs.amount,
          _align: "right",
        },
        {
          text: vm.tn("table.my_selling_price"),
          value: "mySellingPrice",
          sortable: false,
          visible: vm.isMyMarkup,
          _filter: (v, item) => {
            const a = item.amountDiscount || item.amount;
            const b = vm.markupText;
            const diff = Math.round(+(a * (b / 100)).toFixed(2));
            const sellingPrice = Math.round(+(a + diff).toFixed(2));

            if (diff) {
              let vl = `<span class="green--text">(+${vm.$sum(diff)})</span> `;

              const refRtSlPrice = item.productReferentPriceRetailSalePrice;
              if (sellingPrice && refRtSlPrice && sellingPrice > refRtSlPrice) {
                return `${vm.$sum(vl)} <span class="red--text">${vm.$sum(sellingPrice)}</span>`;
              }
              return `${vm.$sum(vl)} ${vm.$sum(sellingPrice)}`;
            }

            return `${vm.$sum(sellingPrice)}`;
          },
          width: vm.$dxcs.price,
          _align: "right",
        },
        {
          text: vm.$t("table.productReferentPriceDate"),
          value: "productReferentPriceDate",
          width: vm.$dxcs.date,
          _filter: (v) => vm.$date(v),
        },
        {
          text: vm.$t("table.productReferentPriceWholeSalePrice"),
          value: "productReferentPriceWholeSalePrice",
          _filter: (v, item) => {
            const val = vm.$number(v);
            if (!val) {
              return vm.$sum(val);
            }

            const a = vm.$number(item.amountDiscount) || vm.$number(item.amount);
            const b = item.productReferentPriceWholeSalePrice;
            const c = (b / a) * 100;
            const diff = +(c - 100).toFixed(2);
            const diffText = diff ? (diff < 0 ? `<span class="red--text">(${vm.$sum(diff)}%)</span>` : `<span class="green--text">(${vm.$sum(diff)}%)</span>`) : "";

            return `${diffText} ${vm.$sum(val)}`;
          },
          width: vm.$dxcs.price,
          _align: "right",
        },
        {
          text: vm.$t("table.productReferentPriceRetailSalePrice"),
          value: "productReferentPriceRetailSalePrice",
          _filter: (v, item) => {
            const val = vm.$number(v);
            if (!val) {
              return vm.$sum(val);
            }

            const a = vm.$number(item.amountDiscount) || vm.$number(item.amount);
            const b = item.productReferentPriceRetailSalePrice;
            const c = (b / a) * 100;
            const diff = +(c - 100).toFixed(2);
            const diffText = diff ? (diff < 0 ? `<span class="red--text">(${vm.$sum(diff)}%)</span>` : `<span class="green--text">(${vm.$sum(diff)}%)</span>`) : "";

            return `${diffText} ${vm.$sum(val)}`;
          },
          width: vm.$dxcs.price,
          _align: "right",
        },
        {
          text: vm.tn("table.load"),
          value: "load",
        },
        {
          text: vm.tn("table.actions.count"),
          value: "actions",
          class: "disable-resize",
          _resize: false,
          width: 85,
        },
      ]
        .map((x) => {
          if (vm.tdSizes[x.value]) x.width = vm.tdSizes[x.value];
          return x;
        })
        .filter((e) => e.visible != false),

    actionsList: (vm) => [
      {
        name: vm.tn("item_actions.move_btn"),
        action: (item) => vm.$router.push(`/basket/move/${item.basketLineItemGuid}/${item.priceListLineItemGuid}`),
      },
    ],

    organizationStatisticBtnTitle() {
      if (this.isShowOrganizationStatistic) {
        return this.tn("organization_statistic.show_not_organization_statistic_btn");
      } else if (this.isShowOrganizationStatistic == false) {
        return this.tn("organization_statistic.show_all_btn");
      } else {
        return this.tn("organization_statistic.show_organization_statistic_btn");
      }
    },

    organizationStatisticRejectBtnTitle() {
      if (this.isShowOrganizationStatisticReject) {
        return this.tn("organization_statistic_reject.show_not_organization_statistic_reject_btn");
      } else if (this.isShowOrganizationStatisticReject == false) {
        return this.tn("organization_statistic_reject.show_all_reject_btn");
      } else {
        return this.tn("organization_statistic_reject.show_organization_statistic_reject_btn");
      }
    },

    getTableHeight() {
      let tbHeight = null;

      switch (this.isShowOrganizationStatistic) {
        case true:
          tbHeight = this.isHide ? this.tableHeight / 2.085 : this.tableHeight / 1.059;
          break;
        case false:
          tbHeight = this.tableHeight;
          break;
        case undefined:
          tbHeight = this.isHide ? this.tableHeight / 2.085 : this.tableHeight / 1.059;
          break;
      }

      if (!this.selected?.priceListLineItemGuid) tbHeight = this.tableHeight;
      return tbHeight;
    },
  },

  watch: {
    selectedRows() {
      this.$refs.table2?.getEntries({ reset: true });
    },
  },

  created() {
    const savedWidth = localStorage.getItem(`${this.ln}_left_side_width`);
    if (savedWidth) this.leftSideWidth = parseInt(savedWidth, 10);
  },

  mounted() {
    document.addEventListener("keydown", this.onDocumentKeyup);
    this.onResizableLeftSide();
  },

  destroyed() {
    document.removeEventListener("keydown", this.onDocumentKeyup);
  },

  methods: {
    ...mapActions("price-list-items", ["GET_API"]),

    getBasketItems(params) {
      const baskets = this.selectedRows.map((x) => x.guid);
      params.options.basketGuids = baskets;
      params.filterData.showByPriceListViewerOrganizationStatistic = this.isShowOrganizationStatistic;
      params.filterData.showByPriceListViewerProductRejects = this.isShowOrganizationStatisticReject;
      return this.GET_API(params);
    },

    onDocumentKeyup(event) {
      if (event.code != "Tab") return;
      event.preventDefault();

      const table1 = this.$refs.table1;
      const table2 = this.$refs.table2;
      if (!this.isDrawer || table1?.checkFocused()) {
        table2.isSavePosition = true;
        table2.setFocusToItem();
      } else {
        table1.isSavePosition = true;
        table1.setFocusToItem();
      }
    },

    onRefresh() {
      this.$refs.table1?.getEntries({ reset: true });
      this.$refs.table2?.getEntries({ reset: true });
    },

    getRowStyle(item) {
      if (!item.isPriceListCurrent) {
        return {
          backgroundColor: "#ff9595",
        };
      } else if (item.isDuplicate) {
        return {
          backgroundColor: "#ffe8e7",
        };
      }
    },

    onShowOrganizationStatistic() {
      if (this.isShowOrganizationStatistic) this.isShowOrganizationStatistic = false;
      else if (this.isShowOrganizationStatistic == false) this.isShowOrganizationStatistic = undefined;
      else this.isShowOrganizationStatistic = true;
      this.$refs.table2?.getEntries({ reset: true });
    },

    onShowOrganizationStatisticReject() {
      if (this.isShowOrganizationStatisticReject) this.isShowOrganizationStatisticReject = false;
      else if (this.isShowOrganizationStatisticReject == false) this.isShowOrganizationStatisticReject = undefined;
      else this.isShowOrganizationStatisticReject = true;
      this.$refs.table2?.getEntries({ reset: true });
    },

    onSelect(v) {
      this.isShowItems = false;
      this.selected = { ...v };
      this.$nextTick(() => {
        if (v?.priceListLineItemGuid) {
          this.isShowItems = true;
          this.$refs.table3?.getEntries({ reset: true });
        }
      });
    },

    onResizableLeftSide() {
      const el = this.$refs.resizable;
      const resizer = el.querySelector('.resizer');
      resizer.addEventListener('mousedown', (e) => {
        e.preventDefault();
        window.addEventListener('mousemove', resize);
        window.addEventListener('mouseup', stopResize);
      });

      const resize = (e) => {
        const width = e.pageX - el.getBoundingClientRect().left;
        if (width > 100 && width < window.innerWidth * 0.5) {
          this.leftSideWidth = width;
        }
      };

      const stopResize = () => {
        window.removeEventListener('mousemove', resize);
        localStorage.setItem(`${this.ln}_left_side_width`, this.leftSideWidth);
      };
    }
  },
};
</script>

<template>
  <v-container fluid>
    <div class="page-wrap">
      <div
        ref="resizable"
        :class="!isDrawer && 'left-side-closed'"
        class="left-side"
        :style="{ width: leftSideWidth + 'px' }"
      >
        <provider-list
          :isFocusable="isDrawer"
          class="left-side-list"
          ref="table1"
          :ln="ln + '.provider_list'"
          :tableHeight="tableHeight"
          @selectedRows="(v) => (selectedRows = v)"
          @refresh="onRefresh"
        />
        <div class="resizer" />
      </div>

      <div
        class="content-side"
        :class="!isDrawer && 'left-side-closed'"
      >
        <price-list-items
          disableCountBold
          :GET_API="getBasketItems"
          :entries="entriesByProduct"
          @markup="(v) => (markupText = v)"
          @isMyMarkup="(v) => (isMyMarkup = v)"
          @select="onSelect"
          :entriesCount="entriesCountByProduct"
          :tableHeaders="tableHeaders"
          :ln="ln"
          :tableHeight="getTableHeight"
          :uniqKey="uniqKey"
          :url="urlBasketItems"
          hideBackBtn
          disableAutofocus
          disableTab
          ref="table2"
          hideDistributorInfo
          disableSort
          isShowTotals
          :actionsList="actionsList"
          :getRowStyle="getRowStyle"
          multiSort
          class="multisort"
          :mustSort="false"
          :v-resize-table="{ tableHeaders: tableHeaders, callback: onUpdateTdSizes }"
        >
          <template #_hasStatistics="slotProps">
            <div class="d-flex align-center">
              <v-btn
                v-if="slotProps.item.hasStatistics"
                color="transparent"
                icon
                x-small
              >
                <img
                  width="18"
                  height="18"
                  src="@/assets/svg/potrebnost.svg"
                  alt=""
                />
              </v-btn>

              <v-btn
                v-if="slotProps.item.hasRejects"
                color="primary"
                icon
                x-small
              >
                <img
                  src="@/assets/svg/reject.svg"
                  alt=""
                  width="18"
                  height="18"
                />
              </v-btn>
            </div>
          </template>

          <template #title>
            <div class="d-flex align-center">
              <v-btn
                color="primary"
                min-width="40"
                width="40"
                height="40"
                min-height="40"
                class="mr-3"
                outlined
                @click="isDrawer = !isDrawer"
                :title="isDrawer ? tn('close') : tn('open')"
              >
                <v-icon v-if="isDrawer"> mdi-arrow-left </v-icon>
                <v-icon v-else> mdi-arrow-right </v-icon>
              </v-btn>
              <div class="py-6"></div>

              <div v-if="selectedRows.length == 1">
                <h3>
                  {{ selectedRows[0].priceListProviderName }}
                </h3>

                <small>
                  <b>{{ tn("time_started") }}:</b>
                  {{ selectedRows[0].timeStarted | dateTime }};
                </small>
              </div>
            </div>
          </template>

          <template #body-append="bodyProps">
            <td
              v-for="(header, i) in bodyProps.headers"
              :key="i"
            >
              <div
                v-if="header.value == 'number'"
                class="text-nowrap text-center"
              >
                {{ entriesCountByProduct | sum }}
              </div>

              <div
                v-else-if="header.value == 'priceListProviderName'"
                class="text-nowrap"
              >
                {{ tn("distributors_total", { 0: $sum(entriesTotals.totalProviderCount) }) }}
              </div>

              <div
                v-else-if="header.value == 'amount'"
                class="text-nowrap text-right"
              >
                {{ entriesTotals.totalAmount | sum }}
              </div>

              <div
                v-else-if="header.value == 'amountDiscount'"
                class="text-nowrap text-right"
              >
                {{ entriesTotals.totalAmountDiscount | sum }}
              </div>

              <div
                v-else-if="header.value == 'actions'"
                class="text-nowrap text-right"
              >
                {{ entriesTotals.totalItemsCount | sum }}
              </div>

              <div v-else />
            </td>
          </template>

          <template #actions>
            <v-btn
              color="primary"
              min-width="40"
              width="40"
              height="40"
              min-height="40"
              outlined
              :title="organizationStatisticBtnTitle"
              @click="onShowOrganizationStatistic"
              class="mr-2"
            >
              <img
                v-if="isShowOrganizationStatistic"
                src="@/assets/svg/potrebnost.svg"
                alt=""
              />
              <img
                v-else-if="isShowOrganizationStatistic == undefined"
                src="@/assets/svg/potrebnost-off.svg"
                alt=""
              />
              <img
                v-else
                src="@/assets/svg/potrebnost-outlined.svg"
                alt=""
              />
            </v-btn>

            <v-btn
              color="primary"
              min-width="40"
              width="40"
              height="40"
              min-height="40"
              outlined
              :title="organizationStatisticRejectBtnTitle"
              @click="onShowOrganizationStatisticReject"
              class="mr-2"
            >
              <img
                v-if="isShowOrganizationStatisticReject"
                src="@/assets/svg/reject.svg"
                alt=""
              />
              <img
                v-else-if="isShowOrganizationStatisticReject == undefined"
                src="@/assets/svg/reject-off.svg"
                alt=""
              />
              <img
                v-else
                src="@/assets/svg/reject-outlined.svg"
                alt=""
              />
            </v-btn>
          </template>
        </price-list-items>

        <ShowPharmacyRequirements
          v-if="isShowItems && isShowOrganizationStatistic != false"
          ref="table3"
          :tableHeight="tableHeight / 2.44"
          :priceListLineItemGuid="selected?.priceListLineItemGuid"
          :amount="selected?.amountDiscount || selected?.amount"
          :uniqKey="ln"
          :isShowSearchBtn="false"
          @isHide="(e) => (isHide = e)"
        />
      </div>
    </div>
  </v-container>
</template>

<style lang="scss" scoped>
.page-wrap {
  display: flex;
  height: 100%;

  .left-side {
    max-width: 50%;
    overflow: hidden;
    transition: all 0.2s;
    position: relative;

    &.left-side-closed {
      max-width: 0;

      .left-side-list {
        max-height: 50vh;
      }
    }
  }

  .resizer {
    width: 10px;
    height: 100%;
    background: transparent;
    position: absolute;
    right: 0;
    top: 0;
    cursor: ew-resize;
  }

  .content-side {
    flex: 3;
    overflow-y: auto;
    position: relative;

    padding-left: 10px;
    margin-left: -1px;

    &.left-side-closed {
      margin-left: -12px;
      padding-left: 12px;
    }
  }
}
</style>
